(function () {

    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {


        $stateProvider
            .state('home', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('search', {
                parent: 'home',
                url: "search?svc&loc&lat&lng&page&sort&search&range&mocs&recs&sucs&frcs&cscs&uscs&lngs&assocs&certs&cmp&query&fvw=c&supplier=false&ctry&inq&spc",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    lat: null,
                    lng: null,
                    range: "50"
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {

                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('supplier-search', {
                parent: 'home',
                url: "supplier-search?svc&loc&lat&lng&page&sort&search&range&mocs&recs&sucs&frcs&cscs&uscs&lngs&assocs&certs&cmp&query&fvw=c&supplier=true&ctry&inq&spc",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/search.html',
                        controller: 'SearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    lat: null,
                    lng: null,
                    range: "50"
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {

                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('searchdetails', {
                parent: 'search',
                url: '/service-providers/{id}',
                data: {
                    authorities: [],
                    //authorities: ['ROLE_USER','ROLE_SP_ADMIN'],
                    pageTitle: 'mobilityexchangeApp.serviceProviders.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/search/searchdetails.html',
                        controller: 'SearchDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('serviceProviders');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                        return ServiceProviders.getprofile({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'service-providers',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('spedit', {
                parent: 'service-providers',
                url: '/{id}/edit?navtype={navtype}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SP_ADMIN']
                },
                params : {
                    navtype: '1'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/service-providers/service-providers-dialog.html',
                        controller: 'ServiceProvidersDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {
                        return ServiceProviders.get({id: $stateParams.id}).$promise;
                    }],

                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            isCopy: 'false',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }

            })


            /*.state('billing-plan', {
                parent: 'entity',
                url: '/billing-plan?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mobilityexchangeApp.billingPlan.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/billing-plan/billing-plans.html',
                        controller: 'BillingPlanController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('billingPlan');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })*/


            .state('relatedcompanies', {
                parent: 'searchdetails',
                url: '/relatedcompanies/{id}',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/search/search.relatedcomp.html',
                        controller: 'ServiceProvidersRelatedCompController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
//

                            previousState: ['$stateParams', function ($stateParams) {
                                var currentStateData = {
                                    spid: $stateParams.id,
                                    sort: 'serviceproviderAddresses.country,asc'
                                };
                                return currentStateData;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('service-providers', null, {reload: 'service-providers'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })


            .state('help', {
                parent: 'home',
                url: "help",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/help.html'
                    }
                }
            })
            .state('about', {
                parent: 'home',
                url: "about",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/about.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('certify', {
                parent: 'about',
                url: "certify",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/certify.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('contactus', {
                parent: 'home',
                url: "contactus",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/contactus.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('faq', {
                parent: 'home',
                url: "faq",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/faq.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('howitworks', {
                parent: 'home',
                url: "howitworks",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/howitworks.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('legal', {
                parent: 'home',
                url: "legal",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/legal.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('listyourbusiness', {
                parent: 'home',
                url: "listyourbusiness",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/listyourbusiness.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('news', {
                parent: 'home',
                url: "news",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/news.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('partnerdatabase', {
                parent: 'home',
                url: "partnerdatabase",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/partnerdatabase.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('presentyourbest', {
                parent: 'home',
                url: "presentyourbest",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/presentyourbest.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('privacy', {
                parent: 'home',
                url: "privacy",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/privacy.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('register2', {
                parent: 'home',
                url: "/register2",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/register.html'
                    }
                }
            })
            .state('sitemap', {
                parent: 'home',
                url: "sitemap.html",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/sitemap.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('termsofuse', {
                parent: 'home',
                url: "termsofuse",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/termsofuse.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('validate', {
                parent: 'home',
                url: "validate",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/validate.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('verification', {
                parent: 'home',
                url: "verification",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/verification.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('howtovalidate', {
                parent: 'searchdetails',
                url: '/howtovalidate',
                data: {
                    authorities: [],
                    pageTitle: 'claimbusiness.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/payments/validationterms.html',
                        controller: 'PayController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ServiceProviders', function ($stateParams, ServiceProviders) {

                        if(!(_.isEmpty($stateParams.id) || _.isUndefined($stateParams.id))) {
                            return ServiceProviders.get({id: $stateParams.id}).$promise;
                        }
                    }],
                    previousState: ['$stateParams', function ($stateParams) {
                        var currentStateData = {
                            spid: $stateParams.id
                        };
                        return currentStateData;
                    }]
                }

            })

            .state('mx-connect-help', {
                parent: 'home',
                url: "mxconnecthelp",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/mxconnecthelp.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('iamxstatusmap', {
                parent: 'home',
                url: "iamxstatusmap",
                reload: true,
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/analytics/iamxstatusmap.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader','$window', function ($translate, $translatePartialLoader,$window) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('customanalytics', {
                parent: 'home',
                url: "customanalytics",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/analytics/customanalytics.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('iamx-dashboard-simple', {
                parent: 'home',
                url: "iamxdashboardsimple",
                data: {
                    /*authorities: ['ROLE_USER']*/
                },
                views: {
                    'content@': {
                        templateUrl: 'app/analytics/iamxdashboardsimple.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('iamx-dashboard', {
                parent: 'home',
                url: "iamxdashboard",
                data: {
                    /*authorities: ['ROLE_USER']*/
                },
                views: {
                    'content@': {
                        templateUrl: 'app/analytics/iamxdashboard.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('pcguidelines', {
                parent: 'home',
                url: "pcguidelines",
                data: {
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/pc-guidelines.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('here2help', {
                parent: 'home',
                url: "here2help",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/here2help.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })


            .state('communicate', {
                parent: 'home',
                url: "communicate",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/supplychain/communicate.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })


            .state('manage', {
                parent: 'home',
                url: "manage",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/supplychain/manage.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })


            .state('rfp', {
                parent: 'home',
                url: "rfp",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/supplychain/rfp.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })


            .state('how-to-claim', {
                parent: 'home',
                url: "howtoclaim",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/howtoclaim.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('how-to-register', {
                parent: 'home',
                url: "howtoregister",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/howtoregister.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('mx-connect-beta', {
                parent: 'home',
                url: "mxconnectbeta",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/mxconnectbeta.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('edit-profile', {
                parent: 'home',
                url: "editprofile",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/editprofile.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('how-to-edit', {
                parent: 'home',
                url: "howtoedit",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/howtoedit.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })


            .state('mx-tutorial', {
                parent: 'home',
                url: "mxtutorial",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/mxtutorial.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })


            .state('iamlearning', {
                parent: 'home',
                url: "iamlearning",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/pathlmsinfo.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })



            .state('how-to-validate', {
                parent: 'home',
                url: "howtovalidate",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/howtovalidate.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('iamx-logo-support', {
                parent: 'home',
                url: "val_logo",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/val_logo.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('contactprefs', {
                parent: 'home',
                url: "contactprefs?key",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/contact_prefs.html',
                        controller: 'ContactPrefsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('supplier-portal', {
                parent: 'app',
                url: '/supplier-portal',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/supplier-portal.html',
                        controller: 'SupplierPortalController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('validation-guidelines', {
                parent: 'home',
                url: "validationguidelines",
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/static-content/validation-guidelines.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            });

    }


})();




